<template>
  <v-row class="white--text contactForm" justify="center">
    <v-col class="d-flex flex-column justify-center" cols="12" lg="6">
      <v-row>
        <v-col class="text-center">
          <h1 class="display-2 mb-7">Contact Us</h1>
          <h2 class="headline px-15">
            Please contact us with any questions or requests that you may have.
            We welcome and appreciate your interest in our company.
          </h2>
          <p class="headline pt-6">Open Monday - Friday 8 am - 5 pm</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="py-7">
          <v-img
            width="170"
            class="mx-auto mb-5"
            src="https://res.cloudinary.com/jlr-contractors/image/upload/v1657977847/Logos/JLR_BusinessCard_BlakeCarter_102621_1_-1-min_h1qh7y.webp"
          />
          <div class="d-flex align-center flex-column title">
            <div class="d-flex align-center mb-3">
              <v-icon size="30" color="primary" class="mr-3">
                mdi-map-marker
              </v-icon>
              <div>
                <p class="mb-0">1052 S 250 East</p>
                <p class="mb-0">Provo, UT</p>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-icon size="30" color="primary" class="mr-3">
                mdi-phone
              </v-icon>
              <a href="tel:801-373-5642">(801) 373-5642</a>
            </div>
          </div>
        </v-col>
        <v-divider vertical class="primary" />
        <v-col cols="6" class="py-7">
          <v-img
            width="170"
            class="mx-auto mb-5"
            src="https://res.cloudinary.com/jlr-contractors/image/upload/v1657977847/Logos/JLRWOODWORK_Cont_Front-min_e0ehg1.webp"
          />
          <div class="d-flex align-center flex-column title">
            <div class="d-flex align-center mb-3">
              <v-icon size="30" color="primary" class="mr-3">
                mdi-map-marker
              </v-icon>
              <div>
                <p class="mb-0">300 S 655 East</p>
                <p class="mb-0">Provo, UT</p>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-icon size="30" color="primary" class="mr-3">
                mdi-phone
              </v-icon>
              <a href="tel:801-768-4467">(801) 768-4467</a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="7"
      lg="5"
      class="d-flex flex-column justify-center my-auto px-16"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-16' : ''"
    >
      <v-form
        @submit.prevent="valid ? submit : validate"
        v-model="valid"
        ref="form"
      >
        <v-text-field
          v-model="fullName"
          label="Full Name *"
          name="fullName"
          outlined
          :rules="nameRules"
          dark
        />
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email *"
            name="email"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-2'"
            outlined
            dark
          />
          <v-text-field
            v-model="phone"
            label="Phone (optional)"
            name="phone"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-2'"
            rows="3"
            outlined
            dark
          />
        </div>
        <v-textarea
          :rules="messageRules"
          v-model="message"
          label="Message *"
          name="message"
          outlined
          dark
        />
        <v-btn
          @click="validate"
          type="submit"
          color="primary"
          width="100%"
          outlined
          rounded
        >
          Submit
        </v-btn>
      </v-form>

      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      valid: false,
      fullName: "",
      email: "",
      phone: "",
      message: "",
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      messageRules: [(v) => !!v || "Message is required"],
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    validate() {
      let result = this.$refs.form.validate();
      if (result) this.submit();
    },
    submit() {
      let data = document.querySelector("form");
      emailjs
        .sendForm(
          "service_beao3vp",
          "template_eifxvdf",
          data,
          "user_Wo4Fk37DCYaeDNB0sONDt"
        )
        .then(
          (result) => {
            if (result.status == 200)
              this.snackbarText = "Message Sent Successfully";
            this.snackbar = true;
          },
          (error) => {
            if (error) this.snackbarText = "Message Failed to Send";
            this.snackbar = true;
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.contactForm {
  padding-top: 100px;
  min-height: 80vh;
}

::v-deep a {
  color: white !important;
}
</style>
